import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { get } from "lodash";
import { graphql } from "gatsby";
import { Button } from "antd";
import gql from "graphql-tag";
import { useLazyQuery } from "@apollo/react-hooks";

import { ContentBlock, TextBlock } from "../../components/Blocks";
import Pages from "../../components/Pages";
import { ContentList } from "../../components/Lists";
import Breadcrumbs from "../../components/Layout/Breadcrumbs";
import { formatURL, removeEmptyKeys, removePreloader } from "../../helpers";
import { Share } from "../../widgets";
import seoData from "../../components/SEO/data";
import NoScript from "../../components/NoScript";
import { showMore } from "../../constants";
import { useThemeContext } from "../../components/Layout/ThemeContext";

export const query = graphql`
	query TvObrazPage {
		hasura {
			media_video_categories(where: {slug: {_in: ["source-of-life", "regained-history", "keys-to-the-ouse", "mothers"]}}, limit: 3) {
				title_full
				main_image
				slug
			}
		}
	}
`;

const FETCH_QUERY = gql`
	query FetchData($tvObrazCategories: [String!], $limit: Int, $offset: Int) {
		media_video_categories(where: {slug: {_in: $tvObrazCategories}}, limit: $limit, offset: $offset) {
			title_full
			main_image
			slug
		}
	}
`;

export default function TvObraz({ data }) {
	const url = "tv_obraz";
	const limit = 3;
	const tvObrazCategories = ["source-of-life", "regained-history", "keys-to-the-ouse", "mothers"];

	const { theme } = useThemeContext();

	function makeData(data = [], type) {
		return data.map(({ title_full, slug, main_image }) => {
			return {
				slug: formatURL(`media/${type}/${slug}`),
				image: main_image && get(main_image, "src"),
				title: title_full,
			};
		});
	}

	const [tvObraz, setTvObraz] = useState(makeData(get(data, "hasura.media_video_categories", []), "tv_obraz"));
	const [offset, setOffset] = useState(limit);

	const [loadTvObraz, { called, loading, error, data: tvObrazData }] = useLazyQuery(
		FETCH_QUERY,
		{
			variables: {
				limit,
				tvObrazCategories,
			},
			fetchPolicy: "cache-and-network",
			partialRefetch: true,
		});

	useEffect(() => {
		if (called && !error && !loading) {
			setTvObraz([...tvObraz, ...makeData(get(tvObrazData, "media_video_categories", []))]);
			setOffset(get(tvObrazData, "media_video_categories", []).length < limit ? null : offset + limit);
		} else if (error) {
			setOffset(null);
		}

		removePreloader(!loading || error);
	}, [loading]);

	return (
		<Pages url={url} entity={get(seoData, "tv_obraz_page", {})}>
			<ContentBlock key={"breadcrumbs"}>
				<div className="container">
					<Breadcrumbs currentLabel={"ТВ-образ"} />
				</div>
			</ContentBlock>
			<ContentBlock key={"main-content"}>
				<div className={"container"}>
					<TextBlock title={"Все телевизионные программы"}>
						<ContentList items={tvObraz} className={"col-12 col-md-4 d-noscript-none"} style={{ width: "100%",
							height: "270px",
							objectFit: "cover" }}/>
						<NoScript>
							<ContentList items={tvObraz} className={"col-12 col-md-4"} style={{ width: "100%",
								height: "270px",
								objectFit: "cover" }}/>
						</NoScript>
					</TextBlock>
					<div className="row justify-content-center mb-5 d-noscript-none">
						<Button
							disabled={loading || !offset}
							loading={loading}
							onClick={() => loadTvObraz({
								variables: {
									offset,
									tvObrazCategories,
								},
							})}
							style={removeEmptyKeys({
								fontFamily: theme.fontFamily,
								fontSize: theme.fontSize.text,
								lineHeight: theme.lineHeight.text,
								color: theme.color.body,
								backgroundColor: theme.color.text,
							})}
						>
							{showMore}
						</Button>
					</div>
				</div>
			</ContentBlock>
			<div className={"container"}>
				<Share url={url} pageTitleShort={"ТВ-образ"} />
			</div>
		</Pages>
	);
}

TvObraz.propTypes = {
	data: PropTypes.object,
};
